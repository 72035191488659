import { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./styles/VideoBlock.module.scss";
import {observer} from 'mobx-react';
import URI from 'urijs';

import PlaceHolderImage from "../../utilities/PlaceHolderImage";
import IframeVideo from '../../utilities/VideoUtils/IframeVideo';
import Html5Video from '../../utilities/VideoUtils/Html5Video';
import {StoreContext} from "../../../stores/StoreLoader";
import {isNotEmptyArray} from "../../../utils/SchoolBlocksUtilities";

const VideoBlock = observer(
    class VideoBlock extends Component {
    static contextType = StoreContext;
    static propTypes = {
        blockObj: PropTypes.object,
        isTitle: PropTypes.bool,
        sizeX: PropTypes.number,
        sizeY: PropTypes.number,
        embedResponsive: PropTypes.bool,
    };

    static defaultProps = {
        sizeX: 1,
        sizeY: 2,
        embedResponsive: true,
    };

    static defaultClassNames = classNames({
        [styles.blockHasPadding]: true,
    });

    render() {
        const {blockObj, embedResponsive} = this.props;
        const {organizationStore, i18nStore} = this.context;

        const embedResponsiveClassName = classNames({
            [styles.embedResponsive]: embedResponsive,
            [styles.embedResponsive16by9]: embedResponsive,
            [styles.embedFullHeight]: !embedResponsive,
            ['sb-blockScroller']: true,
        });

        let item, videoMarkup;

        // TODO: refactor this.  It's very hacky, just getting it to work for a demo
        if (blockObj?.items && isNotEmptyArray(blockObj.items)) {
            const langCode = i18nStore.googleCode;
            let embedLink = blockObj.items?.[0].embedLink;
            embedLink = URI(embedLink)
                .setSearch("cc_load_policy", 1)
                .setSearch("cc_lang_pref", langCode)
                .setSearch("hl", langCode)
                .toString();

            blockObj.items[0].embedLink = embedLink;
        }

        if (this.props.isTitle) {
            item = organizationStore.organization.json_data.settings.title_video;
            if (item?.embedType === "html5") {
                videoMarkup = (
                    <Html5Video
                        title={item.title}
                        embedLink={item.embedLink}
                        poster={item.poster}
                    />
                );
            } else {
                videoMarkup = (
                    <IframeVideo
                        allowFullScreen
                        title={item.title}
                        embedLink={item.embedLink}
                    />
                );
            }
        } else if (
            blockObj?.blank ||
            blockObj?.items === undefined ||
            blockObj?.items === null ||
            blockObj?.items.length === 0
        ) {
            videoMarkup = (
                <PlaceHolderImage
                    type="video"
                    imageSource="/public/images/placeholders/placeholder-video.png"
                />
            );
        } else {
            item = blockObj.items[0];
            if (item?.embedType === "html5") {
                videoMarkup = (
                    <Html5Video
                        title={item.title}
                        embedLink={item.embedLink}
                        poster={item.poster}
                    />
                );
            } else {
                videoMarkup = (
                    <IframeVideo
                        allowFullScreen
                        title={item.title}
                        embedLink={item.embedLink}
                    />
                );
            }
        }

        return (
            <div className={embedResponsiveClassName}>{videoMarkup}</div>
        );
    }
    })
export default VideoBlock;
